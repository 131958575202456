import { Component, Vue } from "vue-property-decorator";

import { STHeader,STFooter } from "@/layout";
import { APQuizHint, APLightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { QuizModel } from "@/models";
import { StCommon } from "../Common";

export class QuizForm {
    sqid = '';
    name = '';
    student = '';
    city = '';
    school = '';
    class = '';
    grade = '';
    unit = '';
    level = '';
    question = [];
    currentQuestion = 0;
    total = 0;
    token = window.localStorage.getItem('studentToken') as string;
}

type response = { [key: string]: unknown };

@Component<ApStudentQuizTopicController>({
    components: {
        STHeader, APQuizHint, APLightbox,STFooter
    }
})
export default class ApStudentQuizTopicController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private quizForm = new QuizForm();
    private stid = window.localStorage.getItem('student_stid') as string;
    private name = window.localStorage.getItem('student_name') as string;
    private token = window.localStorage.getItem('studentToken') as string;
    private qid = this.$route.query.qid as string;
    private audio: any;

    private lightbox: { showLB: boolean, errorMsg: string, confirmLink: string, confirmWord: string, sqid: string } = {
        showLB: false, 
        errorMsg:  '',
        confirmLink: '',
        confirmWord: '',
        sqid: '',
    };

    private errorMsgMap: { [key: string]: string } = {
        studentAnswer: '',
    };

    public async created() {
        // 檢查該組卷該學生是否已填寫過，並撈出題目或題數
        const item: { [key: string]: string } = {
            stid: this.stid,
            qid: this.qid,
            token: this.token,
        }
        const data = await QuizModel.checkWrite(item) as response;
        this.assignDataToForm(data);

        if (!this.quizForm.sqid) { //沒寫過 -> 撈題數 -> show LB
            this.$emit("openQuizHint", true, this.quizForm.total);
        }
    }

    private assignDataToForm(data: response) {
        StCommon.checkToken(data.ERR_CODE as number);

        const item = data.info as {
            sqid: string;
            name: string;
            student: string;
            city: string;
            school: string;
            class: string;
            grade: string;
            unit: string;
            level: string;
            question: [];
            total: number;
            currentQuestion: number;
        };
        this.quizForm.sqid = item.sqid;
        this.quizForm.name = item.name;
        this.quizForm.student = item.student;
        this.quizForm.city = item.city;
        this.quizForm.school = item.school;
        this.quizForm.class = item.class;
        this.quizForm.grade = item.grade;
        this.quizForm.unit = item.unit;
        this.quizForm.level = item.level;
        this.quizForm.question = item.question;
        this.quizForm.total = item.total;
        this.quizForm.currentQuestion = item.currentQuestion;
    }

    //點選開始測驗 -> 撈題目
    public async getPaperQuestionList() {
        const item: { [key: string]: string } = {
            stid: this.stid,
            qid: this.qid,
            token: this.token,
        }
        const data = await QuizModel.stGetPaperQuestion(item) as response;
        this.assignDataToForm(data);
    }

    public playAudio() {
        if(typeof(this.audio) !== 'undefined'){
            this.audio.pause();
        }
        this.quizForm.question.forEach((item, index) => {
            if(this.quizForm.currentQuestion == index+1) {
                this.audio = new Audio(item['audio']);
                this.audio.play();
                return;
            }
        })
    }

    //下一題，存答案
    public async nextQuestion() {
        this.errorMsgMap['studentAnswer'] = '';

        if(typeof(this.audio) !== 'undefined'){
            this.audio.pause();
        }

        if (this.quizForm.question[Number(this.quizForm.currentQuestion) - 1]['studentAnswer'] == '') {
            this.errorMsgMap['studentAnswer'] = '必填'; //放入error message
        } else {
            if (!this.doubleClick) {
                this.doubleClick = true;
              
                const item: { [key: string]: string } = {
                    token: this.token,
                    sqid: this.quizForm.sqid,
                }

                for (const [key, option] of Object.entries(this.quizForm.question)) {
                    if(Number(key) + 1 === this.quizForm.currentQuestion) {
                        item.qqid = option['qqid'];
                        item.studentAnswer = option['studentAnswer'];
                        break;
                    }
                }

                const errNo = await QuizModel.stEditAnswer(item);
                switch (errNo) {
                    case ErrorCode.InvalidToken:
                        StCommon.logout();
                        break;
                    case ErrorCode.Success:
                        this.doubleClick = false;
                        this.quizForm.currentQuestion = this.quizForm.currentQuestion + 1;
                        break;
                    default:
                        this.lightbox.errorMsg = ErrorMessage[errNo];
                        this.lightbox.showLB = true;
                        this.doubleClick = false;
                        break;
                }
            }
        }
    }

    //上一題
    public async prevQuestion() {
        this.errorMsgMap['studentAnswer'] = '';

        if(typeof(this.audio) !== 'undefined'){
            this.audio.pause();
        }

        if (!this.doubleClick) {
            this.doubleClick = true;

            this.quizForm.currentQuestion = this.quizForm.currentQuestion - 1;
            this.doubleClick = false;
        }
    }

    //最後一題，存答案，並提交
    public async completeQuiz() {
        this.errorMsgMap['studentAnswer'] = '';
        
        if(typeof(this.audio) !== 'undefined'){
            this.audio.pause();
        }

        if (this.quizForm.question[Number(this.quizForm.currentQuestion) - 1]['studentAnswer'] == '') {
            this.errorMsgMap['studentAnswer'] = '必填'; //放入error message
        } else {

            if (!this.doubleClick) {
                this.doubleClick = true;

                const item: { [key: string]: string } = {
                    token: this.token,
                    sqid: this.quizForm.sqid,
                }

                for (const [key, option] of Object.entries(this.quizForm.question)) {
                    if(Number(key) + 1 === this.quizForm.currentQuestion) {
                        const qqid = option['qqid'];
                        const studentAnswer = option['studentAnswer'];
                        item.qqid = qqid;
                        item.studentAnswer = studentAnswer;
                        break;
                    }
                }

                const errNo = await QuizModel.stCompleteQuiz(item);
                switch (errNo) {
                    case ErrorCode.InvalidToken:
                        StCommon.logout();
                        break;
                    case ErrorCode.Success:
                        this.doubleClick = false;
                        this.lightbox.errorMsg = '您已完成測驗';
                        this.lightbox.showLB = true;
                        this.lightbox.confirmLink = '/student/quiz/result/info?sqid='+this.quizForm.sqid;
                        this.lightbox.confirmWord = '查看測驗結果';
                        break;
                    default:
                        this.lightbox.errorMsg = ErrorMessage[errNo];
                        this.lightbox.showLB = true;
                        this.doubleClick = false;
                        break;
                }
            }
        }
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}